import state from "./moduleTimesheetState.js";
import mutations from "./moduleTimesheetMutations.js";
import actions from "./moduleTimesheetActions.js";
import getters from "./moduleTimesheetGetters";

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};
