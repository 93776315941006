import axios from "@/axios"

export default {
    fetchStandUp() {
        return axios.get("/stand-ups")
    },
    addStandUp(payload) {
        return axios.post("/stand-ups", payload)
    },
    getStandUp(id) {
        return axios.get(`/stand-ups/${id}`)
    },
    removeStandUp(id) {
        return axios.delete(`/stand-ups/${id}`)
    },
    editStandUp(payload, id) {
        return axios.patch(`/stand-ups/${id}`, payload)
    },
    fetchProject() {
        return axios.get("/projects")
    },
}