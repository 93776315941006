export default {
  SET_PROJECTS(state, projects) {
    state.projects = projects;
  },
  SET_TIMESHEET(state, timesheet) {
    state.timesheet = timesheet;
  },
  INCREASE_COUNT(state) {
    state.time_count++;
  },
  SET_COUNT(state, count) {
    state.time_count = count;
  },
  RECENT_TIME(state, recent_time) {
    state.recent_time = recent_time;
  },
  TOTAL_TIME_WORKED(state, total_duration) {
    state.total_duration = total_duration;
  },
  UPDATE_TIMESHEET(state, timesheet) {
    const itemIndex = state.timesheet.findIndex(
      (time) => time.uuid == timesheet.uuid
    );

    timesheet != -1
      ? (state.timesheet[itemIndex] = timesheet)
      : state.timesheet.push(timesheet);
  },
};
