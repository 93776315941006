import Vue from "vue";
import axios from "axios";
import router from "./router";
import store from "./store";

const UNVALIDATED_ROUTES = ["login", "forgot-password", "reset-password"];

const IGNORED_ROUTES = [
  "skill-mcq",
  "skills",
  "assessment-details",
  "skill-test",
  "timesheet",
  "standup",
];

const INCLUDED_ENDPOINTS = [];

const signOut = () => {
  store.dispatch("auth/signOut");
  router.push("/login");
};
let baseURL = "https://staging-talent-backend.menaget.net/api/v1/talent";

const Axios = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL || baseURL,
});

Axios.interceptors.response.use(
  (response) => {
    if (response.status == 200 || response.status == 201) {
      localStorage.setItem("timestamp", Date.now());
      if (
        INCLUDED_ENDPOINTS.includes(response.config.url) ||
        !IGNORED_ROUTES.includes(router.currentRoute.name)
      ) {
        if (response?.data?.message) {
          Vue.$toast.success(response.data.message);
        }
      }
    }
    return response;
  },
  (error) => {
    const data = error?.response?.data;
    if (error?.response?.status === 401) {
      signOut();
    } else if (error?.response?.status === 400) {
      Vue.$toast.error(data?.message || "Something went wrong!");
    } else if (error?.response?.status === 403) {
      Vue.$toast.error(data.message);
    } else if (error?.response?.status === 404) {
      store.dispatch("setErrorStatus", error.response.status);
      router.push(`/${error.response.status}`);
    } else if (error?.response?.status === 422) {
      Vue.$toast.warning(data.message);
      if (!UNVALIDATED_ROUTES.includes(router.currentRoute.name)) {
        Object.keys(data.errors).forEach((key) => {
          data.errors[key].forEach((err) => {
            Vue.$toast.error(err);
          });
        });
      }
    } else if (error?.response?.status === 429) {
      Vue.$toast.warning(data.message);
    } else if (error?.response?.status >= 500) {
      if (data.message === "Unauthenticated.") {
        signOut();
      } else {
        store.dispatch("setErrorStatus", error.response.status);
        router.push(`/${error.response.status}`);
      }
    }
    return Promise.reject(error);
  }
);

export default Axios;
