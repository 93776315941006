import dashboard from "../../http/requests/dashboard";

export default {
  fetchStats({ commit }) {
    return new Promise((resolve, reject) => {
      dashboard
        .fetchStats()
        .then((response) => {
          console.log("stat", response.data.data);
          commit("SET_STATS", response?.data?.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
