import standup from "@/http/requests/standup";

export default {
    fetchStandUp({ commit }) {
        return new Promise((resolve, reject) => {
          standup
            .fetchStandUp()
            .then((response) => {
              commit("SET_STAND_UPS", response.data.data, { root: true });
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
    },

    addStandUp({ commit }, payload) {
      return new Promise((resolve, reject) => {
        standup.addStandUp(payload)
          .then(response => {
            commit("ADD_STAND_UP", response.data.data, { root: true })
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
  },

  getStandUp({ commit }, id) {
    return new Promise((resolve, reject) => {
      standup.getStandUp(id)
        .then((response) => {
          commit("SET_STAND_UP", response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  removeStandUp({ commit }, id) {
    return new Promise((resolve, reject) => {
      standup.removeStandUp(id)
        .then((response) => {
          commit("REMOVE_STAND_UP", response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  editStandUp({ commit }, payload) {
    return new Promise((resolve, reject) => {
      standup.editStandUp(payload.data, payload.id)
        .then(response => {
          commit("UPDATE_STAND_UP", payload, response.data.data, { root: true })
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchProject({ commit },) {
    return new Promise((resolve, reject) => {
      standup.fetchProject()
        .then((response) => {
          commit("SET_PROJECT", response.data.data, { root: true });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
},
        

}