import axios from "@/axios";

export default {
  fetchProjects() {
    return axios.get("/projects");
  },
  addTime(payload) {
    return axios.post(`/timesheets`, payload);
  },
  fetchTimesheet(currentPage) {
    return axios.get(`/timesheets?page=${currentPage}`);
  },
  fetchRecentTime() {
    return axios.get("timesheets/recent-entries");
  },
  toggleTime(uuid) {
    return axios.post(`/timesheets/${uuid}/toggle`);
  },
};
