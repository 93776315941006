import state from "./moduleAuthState.js";
import mutations from "./moduleAuthMutations.js";
import actions from "./moduleAuthActions.js";
import getters from "./moduleAuthGetters.js";

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};
