import Vue from "vue";
import Vuex from "vuex";

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

Vue.use(Vuex);

import moduleAuth from "./auth/moduleAuth.js";
import moduleSkill from "./skill/moduleSkill.js";
import moduleTimesheet from "./timesheet/moduleTimesheet";
import moduleStandup from "./standup/moduleStandup.js"
import moduleVacation from "./vacation/moduleVacation.js"
import moduleDashboard from "./dashboard/moduleDashboard";

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth: moduleAuth,
    skill: moduleSkill,
    timesheet: moduleTimesheet,
    standup: moduleStandup,
    vacation: moduleVacation,
    dashboard: moduleDashboard,
  },
  strict: process.env.NODE_ENV !== "production",

});
