import axios from "@/axios"

export default {
    fetchVacations() {
        return axios.get("/vacations")
    },
    addVacation(payload) {
        return axios.post("/vacations", payload)
    },
    getVacation(id) {
        return axios.get(`/vacations/${id}`)
    },
    fetchClients() {
        return axios.get("/clients")
    },
}