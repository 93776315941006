import axios from "@/axios";

export default {
  fetchSkills() {
    return axios.get("/skills");
  },
  fetchSkill(id) {
    return axios.get(`/skills/${id}`);
  },
  fetchMcq(id) {
    return axios.get(`/skills/${id}/mcq`);
  },
  fetchAssessment(id) {
    return axios.get(`/skills/${id}/assessment-details`);
  },
  initializeAssessment(payload) {
    return axios.post(`/skills/${payload.id}/initialize`, payload);
  },
  initializeTestAssessment(id) {
    return axios.post(`/skills/${id}/initialize-test`);
  },
  startTest(id) {
    return axios.post(`/skills/${id}/start-test`);
  },
  nextQuestion(payload) {
    return axios.post(`/skills/${payload.id}/mcq/next-question`, payload);
  },
  submitTest(payload) {
    return axios.post(`/skills/${payload.id}/submit-test`, payload.data);
  },
  fetchTags() {
    return axios.get("/skills/tags");
  },
  fetchMcqScores(id) {
    return axios.get(`/skills/${id}/mcq-scores`);
  },
};
