import standup from "../../http/requests/vacation";

export default {
    SET_VACATIONS(state, vacations) {
        state.vacations = vacations;
      },
    ADD_VACATION(state, vacation) {
        state.vacations.unshift(vacation) 
    },
    SET_VACATION(state, vacation) {
      state.vacation = vacation;
    },
    SET_CLIENTS(state, clients) {
      state.clients = clients
    },
    SET_META(state, meta) {
      state.meta = meta;
    },
}