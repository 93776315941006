import standup from "../../http/requests/standup";

export default {
    SET_STAND_UPS(state, standups) {
        state.standups = standups;
      },
    ADD_STAND_UP(state, standup) {
        state.standups.unshift(standup) 
    },
    SET_STAND_UP(state, standup) {
      state.standup = standup;
    },
    REMOVE_STAND_UP(state, id) {
      state.standups = state.standups.filter(standup => standup.id !== id) 
    },
    UPDATE_STAND_UP(state, payload) {
      state.standups = state.standups.map(standup => {
        if (standup.id === payload.id) {
          return Object.assign({}, standup, payload.data)
        }
        return standup
      })
    },
    SET_PROJECT(state, projects) {
      state.projects = projects
    }

}