import state from './moduleStandupState.js'
import mutations from './moduleStandupMutations.js'
import actions from './moduleStandupActions.js'
import getters from './moduleStandupGetters.js'

export default {
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
  }
  