import timesheet from "../../http/requests/timesheet";

export default {
  fetchProjects({ commit }) {
    return new Promise((resolve, reject) => {
      timesheet
        .fetchProjects()
        .then((response) => {
          commit("SET_PROJECTS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  addTime({ commit }, payload) {
    return new Promise((resolve, reject) => {
      timesheet
        .addTime(payload)
        .then((response) => {
          commit("UPDATE_TIMESHEET", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchTimesheet({ commit }, currentPage) {
    return new Promise((resolve, reject) => {
      timesheet
        .fetchTimesheet(currentPage)
        .then((response) => {
          console.log("tt", response.data.data);
          commit("SET_TIMESHEET", response?.data?.data);
          commit("TOTAL_TIME_WORKED", response?.data?.total_duration);
          commit("SET_META", response?.data?.meta);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateCount({ commit }) {
    commit("INCREASE_COUNT");
  },
  fetchRecentTime({ commit }) {
    return new Promise((resolve, reject) => {
      timesheet
        .fetchRecentTime()
        .then((response) => {
          console.log("rt", response.data.data);
          commit("RECENT_TIME", response?.data?.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  toggleTime({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      timesheet
        .toggleTime(uuid)
        .then((response) => {
          commit("UPDATE_TIMESHEET", response?.data?.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
